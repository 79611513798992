import React from "react";
//import * as APP_DATA from "../../../ConstantData";
import { Icons } from "../../../entity/Icons";
import { MitraImage } from '../../../entity/MitraImage';
import { MgtalksImage } from '../../../entity/MgtalksImage';

class MgtalksContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // const { navigateToCareer } = this.props;

    return (
      <div className="flex flex-col">
        <div className="bg-gray-100 rounded-xl flex flex-row p-8 gap-6 mb-6 items-center justify-between">
          <div className="w-11/12 bg-white rounded-xl flex flex-row items-center justify-between relative">
            <div className="absolute top-2 left-3">
              <Icons.SEARCH className="h-10 fill-magnet-primary" />
            </div>
            <input type="text" placeholder="Cari Konten..."
                className="py-4 px-16 rounded-2xl w-full border border-transparent hover:border-magnet-primary focus:border-magnet-primary focus-within:border-magnet-primary"  />
            <div className="px-4 cursor-pointer absolute top-4 right-3">
              <Icons.TIMES className="h-6 w-6 fill-magnet-gray hover:fill-magnet-primary bg-gray-100 rounded-full duration-500" />
            </div>
          </div>
          <button className="w-1/12 bg-magnet-primary rounded-xl p-4 text-magnet-primarylight border border-magnet-primary hover:bg-white hover:text-magnet-primary duration-500">
            Cari
          </button>
        </div>
        <div className="flex flex-col py-6 w-full gap-x-8 mb-24">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-7 gap-y-9">
                <div className="flex flex-col rounded-2xl overflow-hidden shadow-2xl">
                    <img className="w-auto max-h-[420px] object-cover" src={MgtalksImage.mgtalks1} alt="" />
                    <div className="px-6 pt-6 pb-8 flex-1">
                        <div className="flex flex-col h-full justify-between">
                            <div className="flex flex-col items-start">
                                <div className="flex flex-row items-center gap-2 mb-4">
                                    <img alt='' src={MitraImage.logomatagaruda} className='cursor-pointer w-16'></img>
                                    <span>Mata Garuda</span>
                                </div>
                                <h4 className="font-bold text-xl mb-2">Lulusan S2 Gampang Cari Kerja ? - Eps. 1 MG Talks</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col rounded-2xl overflow-hidden shadow-2xl">
                    <img className="w-auto max-h-[420px] object-cover" src={MgtalksImage.mgtalks2} alt="" />
                    <div className="px-6 pt-6 pb-8 flex-1">
                        <div className="flex flex-col h-full justify-between">
                            <div className="flex flex-col items-start">
                                <div className="flex flex-row items-center gap-2 mb-4">
                                    <img alt='' src={MitraImage.logomatagaruda} className='cursor-pointer w-16'></img>
                                    <span>Mata Garuda</span>
                                </div>
                                <h4 className="font-bold text-xl mb-2">Berapa Gaji Lulusan Luar Negeri ? - Eps. 2 MG Talks</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col rounded-2xl overflow-hidden shadow-2xl">
                    <img className="w-auto max-h-[420px] object-cover" src={MgtalksImage.mgtalks3} alt="" />
                    <div className="px-6 pt-6 pb-8 flex-1">
                        <div className="flex flex-col h-full justify-between">
                            <div className="flex flex-col items-start">
                                <div className="flex flex-row items-center gap-2 mb-4">
                                    <img alt='' src={MitraImage.logomatagaruda} className='cursor-pointer w-16'></img>
                                    <span>Mata Garuda</span>
                                </div>
                                <h4 className="font-bold text-xl mb-2">Apakah Lulusan LPDP Harus Pulang Ke Indonesia ? - Eps. 3 MG Talks</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export default MgtalksContent;
