import { API } from '../../entity/API';
import { hideComponent, showComponent } from '../../utils/ShowHideComponent';
import { observer } from 'mobx-react';
import { Page } from '../../entity/Pages';
import { useNavigate } from 'react-router-dom';
import * as APP_DATA from '../../ConstantData';
import axios from 'axios';
import React from 'react'
import RegisterView from './RegisterView';


class RegisterController extends React.Component {
  constructor(props) {
    super(props)
    this.hideComponent = hideComponent.bind(this)
    this.showComponent = showComponent.bind(this)
    this.navigator = this.navigator.bind(this)
    this.moveNextSection = this.moveNextSection.bind(this)
    this.movePreviousSection = this.movePreviousSection.bind(this)
    this.moveToSection = this.moveToSection.bind(this)
    this.onSelectImage = this.onSelectImage.bind(this)
    this.profileUpdate = this.profileUpdate.bind(this)

    this.callAPIRegister = this.callAPIRegister.bind(this)

    this.large_image_message = 'Ukuran gambar lebih besar dari 1 MB'

    this.state = {
      active_section_id: 1,
      image_preview: APP_DATA.PERSON_DEFAULT,
      email: '',
      error_messages: [],
      is_registering: false,
    }

  }

  componentDidMount() {
    // let mgnt_header = document.getElementById('mgnt-header')
    // let mgnt_footer = document.getElementById('mgnt-footer')
    // this.hideComponent(mgnt_header)
    // this.hideComponent(mgnt_footer)
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.image_preview)
    // let mgnt_header = document.getElementById('mgnt-header')
    // let mgnt_footer = document.getElementById('mgnt-footer')
    // this.showComponent(mgnt_header)
    // this.showComponent(mgnt_footer)
  }

  updateValInArray(val, arr) {
    if (!arr.includes(val)) {
      arr.push(val)
    }
    return arr
  }

  removeValInArray(val, arr) {
    if (arr.includes(val)) {
      var index = arr.indexOf(val);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    return arr
  }

  checkPassword() {
    const data = this.props.profileModel.read()
    let err = this.state.error_messages
    if (data.Password) {
      if (data.Password.length < 8) {
        err = this.updateValInArray('Password minimal 8 karakter', err)
      } else {
        err = this.removeValInArray('Password minimal 8 karakter', err)
      }
    }
    if (data.Password && data.RetypePassword) {
      if (data.Password !== data.RetypePassword) {
        err = this.updateValInArray('Password tidak sama', err)
      } else {
        err = this.removeValInArray('Password tidak sama', err)
      }
    }
    this.setState({ error_messages: err })
  }

  checkNull(key_val) {
    // if key_val is null
    if (!key_val) return

    let err = this.state.error_messages

    if (key_val.hasOwnProperty('Email')) {
      err = this.removeValInArray(this.createNullMessage('Email'), err)
      err = this.removeValInArray('The email has already been taken.', err)
    }
    else if (key_val.hasOwnProperty('Password')) {
      err = this.removeValInArray(this.createNullMessage('Password'), err)
    }
    else if (key_val.hasOwnProperty('RetypePassword')) {
      err = this.removeValInArray(this.createNullMessage('Retype password'), err)
    }
    else if (key_val.hasOwnProperty('Fullname')) {
      err = this.removeValInArray(this.createNullMessage('Nama Lengkap'), err)
    }
    else if (key_val.hasOwnProperty('City')) {
      err = this.removeValInArray(this.createNullMessage('Kota Domisili'), err)
    }
    else if (key_val.hasOwnProperty('Country')) {
      err = this.removeValInArray(this.createNullMessage('Negara Domisili'), err)
    }
    else if (key_val.hasOwnProperty('University')) {
      err = this.removeValInArray(this.createNullMessage('Universitas'), err)
    }
    else if (key_val.hasOwnProperty('MajorStudy')) {
      err = this.removeValInArray(this.createNullMessage('Program Studi'), err)
    }
    else if (key_val.hasOwnProperty('Picture')) {
      err = this.removeValInArray(this.createNullMessage('Foto Profil'), err)
    }
    else if (key_val.hasOwnProperty('PK')) {
      err = this.removeValInArray(this.createNullMessage('PK'), err)
    }
    else if (key_val.hasOwnProperty('Expertise')) {
      err = this.removeValInArray(this.createNullMessage('Kategori Keahlian'), err)
    }
    else if (key_val.hasOwnProperty('Description')) {
      err = this.removeValInArray(this.createNullMessage('Deskripsi Diri'), err)
    }
    else if (key_val.hasOwnProperty('Reason')) {
      err = this.removeValInArray(this.createNullMessage('Alasan Berjejaring'), err)
    }

    this.setState({ error_messages: err })
  }

  createNullMessage(field) {
    return 'Bagian ' + field + ' harus diisi.'
  }

  profileUpdate(key_val) {
    this.props.profileModel.update(key_val)

    // make sure model updated before password checking
    if (key_val.hasOwnProperty('Password')
      || key_val.hasOwnProperty('RetypePassword')) {
      this.checkPassword()
    }
    this.checkNull(key_val)
  }

  navigator(path) {
    this.props.navigate(path)
  }

  moveNextSection() {
    let current_section_id = this.state.active_section_id
    this.setState({ active_section_id: current_section_id + 1 })
  }

  movePreviousSection() {
    let current_section_id = this.state.active_section_id
    this.setState({ active_section_id: current_section_id - 1 })
  }

  moveToSection(section_id) {
    this.setState({ active_section_id: section_id })
  }

  onSelectImage(e) {
    if (!e.target.files || e.target.files.length === 0) {
      // no file
      return
    } else {
      let err = this.state.error_messages
      err = this.removeValInArray(this.large_image_message, err)

      if (e.target.files && e.target.files[0].size > 1024000) {
        // file larger than 1 MB
        err.push(this.large_image_message)
        this.setState({ error_messages: err, is_registering: false })
      } else {
        this.setState({ error_messages: err })
      }

      const selectedFile = e.target.files[0]
      const objectUrl = URL.createObjectURL(selectedFile)
      this.checkNull({ 'Picture': 1 })
      this.props.profileModel.update({ 'Picture': objectUrl })
    }
  }

  async callAPIRegister() {
    this.setState({ is_registering: true })
    const null_data = this.props.profileModel.read_null()

    if (null_data.length > 0) {
      const err = this.state.error_messages
      for (let i = 0; i < null_data.length; i++) {
        const null_field = null_data[i]
        err.push(this.createNullMessage(null_field))
      }
      this.setState({ error_messages: err, is_registering: false })
      return
    }

    if (this.state.error_messages.length === 0) {
      const self = this
      const req = async () => {
        const formData = await this.props.profileModel.read_as_formdata()
        try {
          const response = await axios.post(
            API.POST_REGISTER,
            formData,
            {
              headers: {
                // 'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'multipart/form-data'
              }
            });
          // console.log(response.data);
          if (response.data.status === 'OK') {
            self.setState({ is_registering: false })
            self.navigator(Page.LOGIN)
          } else if (response.data.message && response.data.message.length > 0) {
            self.setState({ error_messages: response.data.message, is_registering: false })
          }
        }
        catch (error) {
          if (error.response !== undefined) {
            console.log(error.response);
            self.setState({ is_registering: false, error_messages: [error.response] })
          }
        }
      }
      await req()
    }
  }

  render() {
    document.title = `Daftar | ${APP_DATA.APP_NAME}`
    return (
      <RegisterView
        navigator={this.navigator}
        profileUpdate={this.profileUpdate}
        profile_data={this.props.profileModel.read()}
        active_section_id={this.state.active_section_id}
        moveNextSection={this.moveNextSection}
        movePreviousSection={this.movePreviousSection}
        moveToSection={this.moveToSection}
        image_preview={this.state.image_preview}
        error_messages={this.state.error_messages}
        onSelectImage={this.onSelectImage}
        callAPIRegister={this.callAPIRegister}
        is_registering={this.state.is_registering}
      />
    )
  }
}

export default (props) => {
  const navigate = useNavigate();
  const Controller = observer(RegisterController)
  return (
    <Controller {...props} navigate={navigate} />
  )
}

// export default observer(RegisterController)