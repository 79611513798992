import React from "react";
import { Icons } from "../../../entity/Icons";

class FilterCompanyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      search_name,
      updateSearchName,
      doSearchName } 
    = this.props;

    return (
      <div className="flex flex-col ">
        <div className="bg-gray-100 rounded-xl flex flex-row p-8 gap-6 items-center justify-between">
          <div className="w-11/12 bg-white rounded-xl flex flex-row items-center justify-between">
            <div className="px-4">
              <Icons.SEARCH className="h-10 fill-magnet-primary" />
            </div>
            <input
              className="p-4 w-full appearance-none border-none"
              type="text"
              placeholder="Cari Perusahaan..."
              value={search_name}
              onChange={(e) => updateSearchName(e.target.value)} />
            <div className="px-4 cursor-pointer">
              {search_name && <div className='px-4 cursor-pointer z-[10]'>
                <Icons.TIMES className='h-6 w-6 fill-magnet-gray hover:fill-magnet-primary bg-gray-100 rounded-full duration-500'
                  onClick={() => updateSearchName('')} />
              </div>}
            </div>
          </div>
          <button
            className="w-1/12 bg-magnet-primary rounded-xl p-4 text-magnet-primarylight border border-magnet-primary hover:bg-white hover:text-magnet-primary duration-500"
            onClick={() => doSearchName()}>
            Cari
          </button>
        </div>
        {/* <div className="flex flex-row max-xl:hidden mt-4 gap-6">
          <div className="relative">
            <select className="appearance-none pr-8 pl-6 fill-orange inline text-xl font-semibold select border fill-magnet-primary border-magnet-primary rounded-lg px-4 h-10 max-w-xs mt-4 ">
              <option className="invisible" disabled selected>
                Dipasang
              </option>
              <option>Kemarin</option>
              <option>1 Bulan Lalu</option>
              <option>Hari Ini</option>
            </select>
            <div className="pointer-events-none absolute right-4 top-4 bottom-0 flex items-center px-2 text-gray-700">
              <svg className="h-5 w-5 fill-magnet-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>

          </div>
          <div className="relative">
            <select className="appearance-none pr-12 pl-8 fill-orange inline text-xl font-semibold select border fill-magnet-primary border-magnet-primary rounded-lg  px-4 h-10 max-w-xs mt-4 ">
              <option className="invisible" disabled selected>
                Lokasi
              </option>
              <option>-</option>
              <option>-</option>
              <option>-</option>
            </select>
            <div className="pointer-events-none absolute right-4 top-4 bottom-0 flex items-center px-2 text-gray-700">
              <svg className="h-5 w-5 fill-magnet-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>

          </div>
          <div className="relative">
            <select className="appearance-none pr-12 pl-8 fill-orange inline text-xl font-semibold select border fill-magnet-primary border-magnet-primary rounded-lg  px-4 h-10 max-w-xs mt-4 px-4 ">
              <option className="invisible" disabled selected>
                Spesialisasi
              </option>
              <option>-</option>
              <option>-</option>
              <option>-</option>
            </select>
            <div className="pointer-events-none absolute right-4 top-4 bottom-0 flex items-center px-2 text-gray-700">
              <svg className="h-5 w-5 fill-magnet-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>

          </div>
          <div className="flex flex-grow"></div>
          <div className="flex flex-row mt-4 text-magnet-primary font-semibold">
            <button>Reset Filter</button>
          </div>
        </div> */}
      </div>
    );
  }
}

export default FilterCompanyList;
