import React from 'react'
// import * as APP_DATA from '../../../ConstantData';
import { Icons } from '../../entity/Icons';


class SearchPerson extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }

  render() {
    const {
      search_name,
      updateSearchName,
      doSearchName,
    } = this.props;

    return (
      <div className='flex flex-col'>
        <div className='bg-gray-100 rounded-xl flex flex-row md:p-8 p-4 md:gap-6 gap-2 items-center justify-between'>
          <div className='md:w-11/12 w-9/12 bg-white rounded-xl flex flex-row items-center justify-between'>
            <div className='px-4'>
              <Icons.SEARCH className='h-10 fill-magnet-primary' />
            </div>
            <input className='md:p-4 p-2 w-full md:text-base text-sm focus:outline-none' type='text' placeholder='Pencarian...'
              value={search_name}
              onChange={(e) => updateSearchName(e.target.value)} />
            {search_name && <div className='px-4 cursor-pointer'>
              <Icons.TIMES className='h-6 w-6 fill-magnet-gray hover:fill-magnet-primary bg-gray-100 rounded-full duration-500'
                onClick={() => updateSearchName('')} />
            </div>}
          </div>
          <button className='md:w-1/12 w-3/12 bg-magnet-primary rounded-xl md:p-4 p-2 md:text-base text-sm text-magnet-primarylight border border-magnet-primary hover:bg-white hover:text-magnet-primary duration-500'
            onClick={() => doSearchName()}>
            Cari
          </button>
        </div>
        <div className='mt-4 flex-col hidden'>
          <div className='flex justify-between'>
            <div className='flex flex-row gap-4'>
              <div className='border border-magnet-primary px-2 py-1 rounded-xl'>
                <select className='focus:outline-none'>
                  <option>Dipasang</option>
                  <option>A</option>
                  <option>B</option>
                </select>
              </div>
              <div className='border border-magnet-primary px-2 py-1 rounded-xl'>
                <select className='focus:outline-none'>
                  <option>Lokasi</option>
                  <option>A</option>
                  <option>B</option>
                </select>
              </div>
              <div className='border border-magnet-primary px-2 py-1 rounded-xl'>
                <select className='focus:outline-none'>
                  <option>Jenis</option>
                  <option>A</option>
                  <option>B</option>
                </select>
              </div>
              <div className='border border-magnet-primary px-2 py-1 rounded-xl'>
                <select className='focus:outline-none'>
                  <option>Perusahaan</option>
                  <option>A</option>
                  <option>B</option>
                </select>
              </div>
              <div className='border border-magnet-primary px-2 py-1 rounded-xl'>
                <select className='focus:outline-none'>
                  <option>Spesialisasi</option>
                  <option>A</option>
                  <option>B</option>
                </select>
              </div>
              <div className='border border-magnet-primary px-2 py-1 rounded-xl'>
                <select className='focus:outline-none'>
                  <option>Tingkat</option>
                  <option>A</option>
                  <option>B</option>
                </select>
              </div>
            </div>
            <div>
              <button className='text-magnet-primary hover:text-magnet-primarygradient duration-500 px-2 py-1 rounded-xl font-medium'>
                Reset Filter
              </button>
            </div>
          </div>
          <div className='mt-4 text-sm flex gap-4'>
            <div className='flex items-center justify-center bg-gray-100 rounded-xl px-4 py-1 cursor-default'>
              <span>Filter A</span>
              <button className='hover:bg-gray-300 duration-500 rounded-full ml-2'><Icons.TIMES className='h-4 w-4' /></button>
            </div>
            <div className='flex items-center justify-center bg-gray-100 rounded-xl px-4 py-1 cursor-default'>
              <span>Filter B</span>
              <button className='hover:bg-gray-300 duration-500 rounded-full ml-2'><Icons.TIMES className='h-4 w-4' /></button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SearchPerson