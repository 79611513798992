import React from "react";
import { Icons } from "../../../entity/Icons";
import { Page } from "../../../entity/Pages";
import { TopFunction } from '../../../utils/TopFunction';

class Job extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { jobs, navigator } = this.props;
    const {
      slug,
      partner_logo_url,
      partner_name,
      title,
      city_name,
      state_name,
      min_salary,
      max_salary,
      min_experience,
      log_updated
    } = jobs;

    const job_path = Page.JOBDETAIL.replace(':slug', slug);
    return (
      <div className="border border-magnet-primary shadow flex flex-col p-4 md:p-6 rounded-xl text-neutral-main min-h-full cursor-pointer" 
        onClick={() => {TopFunction(); navigator(job_path)}}>
          <div className="flex flex-1 flex-col items-center justify-center mb-3 md:mb-5">
            <img src={partner_logo_url} alt="Logo perusahaan" className="h-24 min-h-24 max-h-24 min-w-24 max-w-24 rounded-xl mb-3 md:mb-4" />
            <p className="text-lg text-center font-bold my-1">{title}</p>
            <div className="flex flex-1 mb-2 md:mb-4 text-center">
              <p className="text-sm">
                {partner_name}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center mb-1">
            <Icons.MAP_MARKER className="h-4 w-4" />
            <p className="text-sm font-bold ml-2">
              {city_name ? city_name : ''}
              {state_name ? ', ' + state_name : ''}
            </p>
          </div>
          <div className="flex flex-row items-center mb-1">
            <Icons.INVOICE className="h-4 w-4" />
            <p className="text-sm font-bold ml-2">
              {min_salary ? min_salary : ''}
              {max_salary ? ' - ' + max_salary : ''}
            </p>
          </div>
          <div className="flex flex-row items-center mb-3">
            <Icons.SUITCASE_ALT className="h-4 w-4" />
            <p className="text-sm ml-2">{min_experience}</p>
          </div>
          <div className="flex flex-row items-center justify-end mb-1">
            <Icons.CLOCK className="h-4 w-4 fill-magnet-green-dark" />
            <p className="text-xs text-magnet-green-dark ml-2">{log_updated}</p>
          </div>
      </div>
    );
  }
}

export default Job;
