import React from "react";
import MgtalksContent from "./sections/MgtalksContent";

class MgtalksView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="flex flex-col items-center font-poppins">
        <div className="w-10/12">
          <div className="flex flex-col gap-8 mt-10">
            <div className="flex flex-col gap-4 text-center">
              <h2 className="text-4xl font-semibold text-center text-magnet-primary">Mata Garuda Talks</h2>
            </div>
            <MgtalksContent />
          </div>
        </div>
      </div>
    );
  }
}

export default MgtalksView;
