import React from 'react'
// import { Icons } from '../../../entity/Icons'


class ProfileInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }

  render() {
    const {
      // navigator
      onSelectImage,
      // image_preview,
      profileUpdate,
      profile_data,
    } = this.props;

    const image_preview = profile_data.Picture
    let asteric_symbol = <span className="text-magnet-rose-normal">&nbsp;*</span>

    return (
      <div>
        <p className="text-xl font-bold text-magnet-rose-normal mb-6">
          Isi informasi profil anda
        </p>
        <div className='flex md:flex-row flex-col justify-between gap-6'>
          <div className='flex flex-col gap-6 w-full'>
            <div>
              <div className="md:text-lg text-sm md:font-semibold md:mb-0 mb-2">
                <p>Nama Lengkap{asteric_symbol}</p>
              </div>
              <input
                className="md:text-base text-sm shadow appearance-none border rounded-lg w-full md:py-4 py-2 px-4  text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                id="fullname"
                type="text"
                placeholder="Masukkan nama lengkap"
                value={profile_data.Fullname}
                onChange={(e) => { profileUpdate({ 'Fullname': e.target.value }); }}
              ></input>
            </div>
            <div>
              <div className="md:text-lg text-sm md:font-semibold md:mb-0 mb-2">
                <p>Kota Domisili{asteric_symbol}</p>
              </div>
              <input
                className="md:text-base text-sm shadow appearance-none border rounded-lg w-full md:py-4 py-2 px-4  text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                id="city"
                type="text"
                placeholder="Masukkan kota domisili"
                value={profile_data.City}
                onChange={(e) => { profileUpdate({ 'City': e.target.value }); }}
              ></input>
            </div>
            <div>
              <div className="md:text-lg text-sm md:font-semibold md:mb-0 mb-2">
                <p>Negara Domisili{asteric_symbol}</p>
              </div>
              <input
                className="md:text-base text-sm shadow appearance-none border rounded-lg w-full md:py-4 py-2 px-4  text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                id="country"
                type="text"
                placeholder="Masukkan negara domisili"
                value={profile_data.Country}
                onChange={(e) => { profileUpdate({ 'Country': e.target.value }); }}
              ></input>
            </div>
            <div>
              <div className="md:text-lg text-sm md:font-semibold md:mb-0 mb-2">
                <p>Universitas{asteric_symbol}</p>
              </div>
              <input
                className="md:text-base text-sm shadow appearance-none border rounded-lg w-full md:py-4 py-2 px-4  text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                id="university"
                type="text"
                placeholder="Masukkan universitas (yang dibiayai LPDP terakhir)"
                value={profile_data.University}
                onChange={(e) => { profileUpdate({ 'University': e.target.value }); }}
              ></input>
            </div>
            <div>
              <div className="md:text-lg text-sm md:font-semibold md:mb-0 mb-2">
                <p>Program Studi{asteric_symbol}</p>
              </div>
              <input
                className="md:text-base text-sm shadow appearance-none border rounded-lg w-full md:py-4 py-2 px-4  text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                id="majorStudy"
                type="text"
                placeholder="Masukkan program studi (yang dibiayai LPDP terakhir)"
                value={profile_data.MajorStudy}
                onChange={(e) => { profileUpdate({ 'MajorStudy': e.target.value }); }}
              ></input>
            </div>
          </div>

          <div className='flex flex-col gap-6 w-full'>
            <div>
              <div className="md:text-lg text-sm md:font-semibold md:mb-0 mb-2">
                <p>Foto Profil{asteric_symbol}</p>
                <p className='text-xs font-light mb-2'>Ukuran maksimal 1 MB</p>
              </div>
              {image_preview
                ? <img alt='preview' id='preview-photo' src={image_preview} className='bg-gray-100 h-40 w-40 m-4 border border-magnet-rose-normal object-cover'></img>
                : <img alt='preview' id='preview-photo' src={image_preview} className='bg-gray-100 h-40 w-40 m-4'></img>}
              <input
                className="md:text-base text-sm shadow appearance-none border rounded-xl w-full md:py-4 py-2 px-4 text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline "
                id="profile_picture"
                type="file"
                accept="image/png, image/jpeg"
                onChange={(e) => onSelectImage(e)}
              ></input>
            </div>
            <div>
              <div className="md:text-lg text-sm md:font-semibold md:mb-0 mb-2">
                <p>Nomor PK{asteric_symbol}</p>
              </div>
              <input
                className="md:text-base text-sm shadow appearance-none border rounded-lg w-full md:py-4 py-2 px-4  text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                id="pk"
                type="number"
                placeholder="Masukkan nomor PK (angka saja)"
                value={profile_data.PK}
                onChange={(e) => { profileUpdate({ 'PK': e.target.value }); }}
              ></input>
            </div>
            <div>
              <div className="md:text-lg text-sm md:font-semibold md:mb-0 mb-2">
                <p>Kategori Keahlian atau Minat{asteric_symbol}</p>
                <p className='text-xs font-light mb-2'>Jika lebih dari satu, pusahkan dengan koma (,)</p>
              </div>
              <input
                className="md:text-base text-sm shadow appearance-none border rounded-lg w-full md:py-4 py-2 px-4  text-gray-700 focus:outline-magnet-rose-normal focus:shadow-outline"
                id="expertise"
                type="text"
                placeholder="Masukkan kategori keahlian"
                value={profile_data.Expertise}
                onChange={(e) => { profileUpdate({ 'Expertise': e.target.value }); }}
              ></input>
            </div>
          </div>
        </div>
        <hr className="border-magnet-rose-normal mt-8" />
      </div>
    );
  }
}


export default ProfileInfo