import React from "react";
import HTMLReactParser from "html-react-parser";

class HeaderDetailCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { detail_company } = this.props;
    return (
      <div className="w-full my-4 md:my-8 flex flex-col text-neutral-main">
        <div className="w-full bg-white shadow p-4 md:p-8 rounded-2xl flex flex-col">
          <div className="flex flex-col md:flex-row w-full">
            <div className="md:mr-4 mb-4 md:mb-0">
              <div className="border border-neutral-disabled rounded-xl p-4">
                <img className="w-full sm:min-w-48 sm:max-w-48 sm:min-h-48 sm:max-h-48 md:min-w-32 md:max-w-32 md:max-h-32 md:min-h-32 object-cover rounded-xl"
                  src={detail_company.logo_url}
                  onError={({ currentTarget }) => {
                    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                      currentTarget.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/768px-Windows_10_Default_Profile_Picture.svg.png";
                    } else {
                      currentTarget.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/768px-Windows_10_Default_Profile_Picture.svg.png";
                    }
                    currentTarget.onerror = null; // prevents looping
                  }} />
              </div>
            </div>
            <div className="ml-4 flex flex-col w-full">
              <h2 className="font-montserrat font-bold text-xl sm:text-2xl lg:text-3xl mb-2">{detail_company.name}</h2>
              <p className="text-sm md:text-sm mb-2">{HTMLReactParser(`${detail_company.description}`)}</p>
              <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 md:gap-6 mt-4">
                <div className="flex flex-col gap-6 pr-4">
                  <div className="flex flex-row w-full">
                    <p className="w-5/12 md:w-3/12 text-neutral-muted text-sm md:text-base">Kantor Pusat</p>
                    <p className="w-7/12 md:w-9/12 ml-3 pl-4 font-semibold text-sm md:text-base">{detail_company.city_name} , {detail_company.state_name}</p>
                  </div>
                  <div className="flex flex-col gap-3 md:gap-0 md:flex-row w-full flex-wrap">
                    <p className="w-5/12 md:w-3/12 text-neutral-muted text-sm md:text-base pt-3">Industri</p>
                    <div className="w-7/12 md:w-9/12 flex flex-wrap">
                      {/* {
                        detail_company.industries.map(function (item, i) {
                          <span className="font-semibold text-sm bg-primary-main text-white rounded-lg p-2 m-1">{item.name}</span>
                        })
                      } */}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <div className="flex flex-row w-full">
                    <p className="w-5/12 md:w-3/12 text-neutral-muted text-sm md:text-base">Situs</p>
                    <a href={(detail_company.site_url ?? '#')} className="w-7/12 md:w-9/12 ml-3 pl-4 font-semibold text-sm md:text-base">{detail_company.site_url}</a>
                  </div>

                  <div className="flex flex-row w-full">
                    <p className="w-5/12 md:w-3/12 text-neutral-muted text-sm md:text-base">Ukuran Perusahaan</p>
                    <p className="w-7/12 md:w-9/12 ml-3 pl-4 font-semibold text-sm md:text-base">{detail_company.range_workers}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-8" />
          <div className="flex flex-col sm:flex-row justify-center items-center sm:justify-end">
            <a href="#" className="flex px-4 md:px-8 py-3 text-sm font-bold bg-primary-main rounded-xl text-white">
              <span>Lihat Lowongan Pekerjaan&nbsp;</span>
              <span className="hidden md:block"> di {detail_company.name}</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderDetailCompany;
