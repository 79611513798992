import React from 'react'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import RootStore from './model/RootStore'

// import HeaderController from './view/header/HeaderController'
// import FooterController from './view/footer/FooterController'

import CompanyController from "./view/company-page/CompanyController";
import CourseController from "./view/course-page/CourseController";
import CareerController from "./view/career-page/CareerController";
import LandingController from './view/landing-page/LandingController'
import RegisterController from './view/user-register/RegisterController'
import LoginController from './view/user-login/LoginController'
import { Page } from './entity/Pages'
import DashboardController from './view/network/NetworkController'
import AgendaController from './view/agenda-page/AgendaController'
import HeaderProvide from './HeaderProvide'
import FooterProvide from './FooterProvide'
import MitraRegisterController from './view/mitra-register/MitraRegisterController'
import BeritaController from './view/berita-page/BeritaController'
import MitraController from './view/mitra-page/MitraController'
import MitraLoginController from './view/mitra-login/MitraLoginController'
import UserProfileController from './view/user-profile/UserProfileController'
import CallforbookController from './view/callforbook-page/CallforbookController'
import MgtalksController from './view/mgtalks-page/MgtalksController'
import AboutusController from './view/aboutus-page/AboutusController'
import FaqController from './view/faq-page/FaqController'
import VerifyEmailController from './view/user-verifyemail/VerifyEmailController';
import ForgetPasswordController from './view/user-forget-password/ForgetPasswordController';
import JobController from './view/job-page/JobController';
import CompanyDetailController from './view/company-detail-page/CompanyDetailController';
import JobDetailController from './view/job-detail-page/JobDetailController';
// import CareerController from './view/career-page/CareerController'


class Provider extends React.Component {
  constructor(props) {
    super(props)
    // this.userModel = props.store[RootStore.type.USER_MODEL]
    this.profileModel = props.store[RootStore.type.PROFILE_MODEL]
  }

  render() {

    return (
      <BrowserRouter>
        <div className='min-h-screen flex flex-col justify-between'>
          <HeaderProvide />
          <Routes>
            <Route path={Page.HOME} element={<LandingController />} />
            {/* <Route path="/career" element={<CareerController />} /> */}
            <Route path={Page.REGISTER} element={<RegisterController profileModel={this.profileModel} />} />
            <Route path={Page.LOGIN} element={<LoginController profileModel={this.profileModel} />} />
            <Route path={Page.VERIFY} element={<VerifyEmailController />} />
            <Route path={Page.FORGET_PASSWORD} element={<ForgetPasswordController />} />
            <Route path={Page.NETWORK} element={<DashboardController />} />
            <Route path={Page.USER_PROFILE} element={<UserProfileController profileModel={this.profileModel} mode={'view'} />} />
            <Route path={Page.USER_PROFILE_EDIT} element={<UserProfileController profileModel={this.profileModel} mode={'edit'} />} />
            <Route path={Page.AGENDA} element={<AgendaController />} />
            <Route path={Page.PAGES} element={<BeritaController />} />
            <Route path={Page.COURSE} element={<CourseController />} />
            <Route path={Page.CAREER} element={<CareerController />} />
            <Route path={Page.COMPANY} element={<CompanyController />} />
            <Route path={Page.COMPANYDETAIL} element={<CompanyDetailController />} />
            <Route path={Page.JOB} element={<JobController />} />
            <Route path={Page.JOBDETAIL} element={<JobDetailController />} />
            <Route path={Page.LOGINMITRA} element={<MitraLoginController />} />

            <Route path={Page.REGISTERMITRA} element={<MitraRegisterController />} />
            <Route path={Page.MITRA} element={<MitraController />} />
            <Route path={Page.CALLFORBOOK} element={<CallforbookController />} />
            <Route path={Page.MGTALKS} element={<MgtalksController />} />
            <Route path={Page.ABOUTUS} element={<AboutusController />} />
            <Route path={Page.FAQ} element={<FaqController />} />
          </Routes>
          <FooterProvide />
        </div>
      </BrowserRouter>
    )
  }
}

export default Provider
