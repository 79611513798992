import logo_mg from '../image/logo.svg'
import logo_magnet from '../image/logo-magnet.svg'
import new_logo_magnet from '../image/new-logo.png'
import working_woman from '../image/working-woman.svg'
import company_example from '../image/company-example.svg'
import coffee from '../image/coffee.svg'
import city from '../image/city.png'
import visa from '../image/visa.svg'
import laptop from '../image/laptop.svg'
import apple_download from '../image/apple-download.svg'
import googleplay_download from '../image/googleplay-download.svg'
import phone_hold from '../image/phone-hold.svg'
import persondefault from '../image/person-default.svg'


export const APP_NAME = "Magnet | Mata Garuda Network"

export const LOGO_MAGNET = logo_magnet
export const NEW_LOGO_MAGNET = new_logo_magnet
export const LOGO_MG = logo_mg
export const WORKING_WOMAN = working_woman
export const COMPANY_EXAMPLE = company_example
export const APPLE_DOWNLOAD = apple_download
export const GOOGLEPLAY_DOWNLOAD = googleplay_download
export const PHONE_HOLD = phone_hold
export const PERSON_DEFAULT = persondefault

export const COFFEE = coffee
export const CITY = city
export const VISA = visa
export const LAPTOP = laptop

export const COLOR_FILTER = {
  BLACK: { filter: "saturate(0%) brightness(0%)" },
  WHITE: { filter: "saturate(0%) brightness(200%)" },
}

export const USER_REGISTRATION_SECTION = {
  credential: 1,
  profile: 2,
  socialmedia: 3,
}

export const MAX_PAGE_NUMBER_SHOWED = 5

export const DEFAULT_PROFILE = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMlBnRrCavZc6_4Arp_oLv6z6wwIEzLwZEBQ&usqp=CAU'


