var API_HOST;
// console.log('process.env.NODE_ENV', process.env.NODE_ENV)
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  API_HOST = 'http://127.0.0.1:8003'
} else if (process.env.NODE_ENV === 'staging') {
  API_HOST = 'https://test-api.magnet.or.id'
} else {
  API_HOST = 'https://app.magnet.or.id'
}

export const API = {
  HOST: API_HOST,

  POST_REGISTER: API_HOST + '/v1/user/register',
  POST_LOGIN: API_HOST + '/v1/user/login',
  POST_VERIFY_EMAIL: API_HOST + '/v1/user/verify',
  POST_FORGETPW_SEND_EMAIL: API_HOST + '/v1/user/forget-password/send-email',
  POST_FORGETPW_CHECK_KEY: API_HOST + '/v1/user/forget-password/check-key',
  POST_FORGETPW_SET_PW: API_HOST + '/v1/user/forget-password/set-password',
  GET_JWT: API_HOST + '/v1/user/jwt',

  GET_USER_PROFILE: API_HOST + '/v1/profile/user',
  POST_USER_PROFILE: API_HOST + '/v1/profile/user',

  GET_USERS: API_HOST + '/v1/networks/users',
  GET_SEARCH_USERS: API_HOST + '/v1/networks/search',
  GET_CATEGORIES: API_HOST + '/v1/networks/categories',

  GET_AGENDA: API_HOST + '/v1/agenda/bymonth',

  GET_COMPANIES: API_HOST + '/v1/partners',

  GET_JOBS: API_HOST + '/v1/jobvacancies',

  GET_PROVINCES: API_HOST + '/v1/states',
  GET_POSITIONS: API_HOST + '/v1/positions',
  GET_INDUSTRIES: API_HOST + '/v1/industries'
}
