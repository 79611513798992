import { Page } from "../../entity/Pages";
import { useNavigate } from "react-router-dom";
import * as APP_DATA from "../../../core/ConstantData";
import MitraView from "./MitraView";
import React from "react";

class MitraController extends React.Component {
  constructor(props) {
    super(props);
    this.navigator = this.navigator.bind(this);
    this.state = {};
  }

  navigator(path) {
    this.props.navigate(path)
  }

  render() {
    document.title = `MITRA | ${APP_DATA.APP_NAME}`
    return <MitraView navigator={this.navigator} />;
  }
}

export default (props) => {
  const navigate = useNavigate();
  return <MitraController {...props} navigate={navigate} />;
};
