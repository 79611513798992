import React from 'react'
import ProfileCard from '../cards/ProfileCard'
import SearchPerson from '../cards/SearchPerson'
import network_banner_img from '../../../image/network-banner.svg'
import NetworkSearchBar from '../cards/NetworkSearchBar'
import NetworkSearchBarMobile from '../cards/NetworkSearchBarMobile'


class NetworkView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }

  render() {
    const {
      navigator,
      search_name,
      updateSearchName,
      doSearchName,
      updateSelectedCategory,
      updateCurrentPage,
      categories,
      category,
      users,
      page_number_info,
    } = this.props

    return (
      <div className='flex flex-col flex-grow font-inter'>
        <div className='md:flex hidden relative mb-60'>
          <img className='absolute w-full -z-[1]' alt='' src={network_banner_img}></img>
          <div className='absolute z-[1] left-0 top-0 flex w-full items-center justify-center'>
            <div className='flex flex-col gap-4 w-10/12 py-12'>
              <div>
                <h1 className=' text-white font-bold text-5xl'>
                  Get connected and collaborate
                </h1>
              </div>
              <div>
                <NetworkSearchBar
                  search_name={search_name}
                  updateSearchName={updateSearchName}
                  doSearchName={doSearchName}
                  categories={categories}
                  category={category}
                  updateSelectedCategory={updateSelectedCategory}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='flex md:hidden mb-12 bg-gradient-to-r from-magnet-rose-normal to-magnet-orange-normal'>
          {/* <img className='absolute w-full -z-[1]' alt='' src={network_banner_img}></img> */}
          <div className='z-[1] left-0 top-0 flex w-full items-center justify-center'>
            <div className='flex flex-col gap-4 w-10/12 py-12'>
              <div>
                <h1 className=' text-white font-bold text-2xl'>
                  Get connected and collaborate
                </h1>
              </div>
              <div>
                <NetworkSearchBarMobile
                  search_name={search_name}
                  updateSearchName={updateSearchName}
                  doSearchName={doSearchName}
                  categories={categories}
                  category={category}
                  updateSelectedCategory={updateSelectedCategory}
                />
              </div>
            </div>
          </div>
        </div>


        <div className='w-full flex items-center justify-center'>
          <div className='w-10/12 flex flex-col gap-8 items-center justify-center'>
            <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'>
              {users.map(function (user, i) {
                return <ProfileCard key={i} user={user} navigator={navigator} updateSelectedCategory={updateSelectedCategory} category={category} />
              })}
            </div>
            <div>
              {page_number_info.total_page > 0
                && <div className='flex md:flex-row flex-col justify-center items-center gap-2'>
                  <p>Pilih halaman</p>
                  <div className='flex flex-row'>
                    {page_number_info.is_show_far_left_page
                      && <button className='mx-1 text-sm w-8 py-1 rounded-lg duration-500 border border-gray-500 bg-white text-gray-500 hover:text-white hover:border-magnet-rose-normal hover:bg-magnet-rose-normal'
                        onClick={() => updateCurrentPage(1)}>
                        1
                      </button>}
                    {page_number_info.is_show_far_left_page && <p className='font-bold'>. . .</p>}
                    {page_number_info.active_page_number.map(function (page_num, i) {
                      let btn_bg = ''
                      if (page_num === page_number_info.current_page) {
                        btn_bg = ' border border-gray-500 bg-gray-500 text-white hover:border-gray-500 hover:bg-gray-500'
                      } else {
                        btn_bg = ' border border-gray-500 bg-white text-gray-500 hover:text-white hover:border-magnet-rose-normal hover:bg-magnet-rose-normal'
                      }
                      return <button key={i} className={'mx-1 text-sm w-8 py-1 rounded-lg duration-500' + btn_bg}
                        onClick={() => updateCurrentPage(page_num)}>
                        {page_num}
                      </button>
                    })}
                    {page_number_info.is_show_far_right_page && <p className='font-bold'>. . .</p>}
                    {page_number_info.is_show_far_right_page
                      && <button className='mx-1 text-sm w-8 py-1 rounded-lg duration-500 border border-gray-500 bg-white text-gray-500 hover:text-white hover:border-magnet-rose-normal hover:bg-magnet-rose-normal'
                        onClick={() => updateCurrentPage(page_number_info.total_page)}>
                        {page_number_info.total_page}
                      </button>}
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NetworkView