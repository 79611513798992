import { Icons } from '../../entity/Icons'
import { Page } from '../../entity/Pages'
import React from 'react'
import Flag from 'react-flagkit';
import { FixSocialMediaUrls } from '../../utils/SocialMediaLink';
import { read_username } from '../../utils/JwtToken';
import { TopFunction } from '../../utils/TopFunction';


class UserProfileView extends React.Component {
  state = {

  }

  render() {
    const {
      navigate,
      profile_data: profile_data_raw,
      is_own_profile,
    } = this.props

    const profile_data = FixSocialMediaUrls(profile_data_raw)

    return (
      <div className='flex flex-col flex-grow font-poppins items-center justify-center'>
        <div className='flex flex-col w-10/12 py-4 gap-6 md:text-base text-sm'>
          <div className='flex justify-start'>
            <div className='flex justify-center items-center cursor-pointer hover:fill-magnet-rose-normal hover:text-magnet-rose-normal duration-500'
              onClick={() => navigate(Page.NETWORK)}>
              <Icons.ANGLE_LEFT className='h-10 w-10 cursor-pointer' />
              <p className=''>Back to network page</p>
            </div>
          </div>

          <div className='relative h-48 w-full bg-gradient-to-r from-magnet-rose-normal to-magnet-orange-normal'>
            <div className='absolute top-8 left-12 flex flex-col gap-4'>
              <p className='text-white text-4xl font-bold'>Get Connected</p>
              <img alt='preview' id='preview-photo' src={profile_data.Picture} className='bg-gray-100 h-32 w-32 object-cover rounded-lg shadow-lg'
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/768px-Windows_10_Default_Profile_Picture.svg.png";
                }}></img>
              <div className='flex flex-col gap-2 text-magnet-black-normal'>
                <div>
                  <p className='font-bold'>{profile_data.Fullname}</p>
                  {is_own_profile && <p className='text-xs text-magnet-black-light'>{profile_data.Email} <span className='italic font-extralight'>(private to you)</span></p>}
                </div>

                <div className='hidden md:flex gap-2 text-magnet-black-light text-sm'>
                  <p className=''>{profile_data.University}</p>
                  <p className='font-black'>•</p>
                  <p className=''>{profile_data.MajorStudy}</p>
                </div>
                <div className='flex flex-col md:hidden text-magnet-black-light text-sm'>
                  <p className=''>{profile_data.MajorStudy}, <span className='font-semibold'>{profile_data.University}</span></p>
                </div>

                <div className='flex flex-wrap gap-1'>
                  <button className='px-2 py-1 border bg-gray-100 rounded-md text-magnet-black-light hover:text-magnet-rose-normal text-xs duration-500'>
                    PK-{profile_data.PK}
                  </button>
                </div>
                <div className='flex items-center gap-2 text-magnet-black-light'>
                  <Flag country="ID" className='shadow-lg shadow-gray-300' />
                  <p className=''>{profile_data.City}, {profile_data.Country}</p>
                </div>
                <div className='flex gap-2 mt-2'>
                  <button className='bg-gradient-to-r from-magnet-rose-normal to-magnet-orange-normal hover:opacity-80 duration-500 flex gap-1 items-center text-white px-4 py-2 rounded-lg text-xs font-light'
                    onClick={() => {
                      if (profile_data.Whatsapp) {
                        window.open(profile_data.Whatsapp, '_blank')
                      } else if (profile_data.Instagram) {
                        window.open(profile_data.Instagram, '_blank')
                      } else if (profile_data.Linkedin) {
                        window.open(profile_data.Linkedin, '_blank')
                      } else if (profile_data.Twitter) {
                        window.open(profile_data.Twitter, '_blank')
                      } else {
                        this.setState({ is_no_socmed: true })
                      }
                    }}>
                    <Icons.ENVELOPE className='stroke-white' />
                    Say Hello
                  </button>
                  <button className='flex hover:bg-gray-100 duration-500 gap-1 items-center px-4 py-2 rounded-lg text-xs font-light border'
                    onClick={() => {
                      navigator.share({
                        title: profile_data.Fullname + "'s " + document.title,
                        text: "Halo, saya " + profile_data.Fullname + ", mari bergabung bersama saya di Mata Garuda Network.",
                        url: window.location.href
                      })
                    }}>
                    <Icons.SHARE className='stroke-magnet-black-normal' />
                    Share Profile
                  </button>
                </div>
                <div>
                  {this.state.is_no_socmed && <p className='text-xs text-magnet-rose-normal'>Tidak ditemukan sosial media dari pengguna ini</p>}
                  <p></p>
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row gap-4'>
            <div className='flex flex-col md:w-8/12 gap-4'>
              <div className='h-60'></div>
              <div className='border rounded-xl'>
                <div className="p-4 font-semibold">
                  <p>Deskripsi Diri {profile_data.Fullname}</p>
                </div>
                <div className='rounded-xl w-full p-4 border-t border-gray-200 bg-gray-50 min-h-[300px]'>
                  <textarea
                    className="resize-none min-h-[300px] w-full bg-transparent"
                    id="deksripsi"
                    value={profile_data.Description}
                    disabled
                  ></textarea>
                </div>
              </div>
              <div className='border rounded-xl'>
                <div className="p-4 font-semibold">
                  <p>Keahlian</p>
                </div>
                <div className="rounded-xl w-full p-4 border-t border-gray-200 bg-gray-50 min-h-[100px]">
                  <div className='flex gap-2 flex-wrap'>
                    {profile_data.Expertise.split(",").map(function (item, i) {
                      item = item.trim()
                      item = item.charAt(0).toUpperCase() + item.substring(1);
                      return <button key={i}
                        className={'px-2 border bg-white hover:bg-gray-100 rounded-md text-magnet-black-light  duration-500'}>
                        {item}
                      </button>
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col gap-4 md:w-4/12'>
              <div className='border rounded-xl'>
                <div className="p-4 font-semibold">
                  <p>Alasan Berjejaring</p>
                </div>
                <div className='rounded-xl w-full p-4 border-t border-gray-200 bg-gray-50 min-h-[300px]'>
                  <textarea
                    className="resize-none min-h-[300px] w-full bg-transparent"
                    id="deksripsi"
                    value={profile_data.Reason}
                    disabled
                  ></textarea>
                </div>
              </div>
              <div className='border rounded-xl'>
                <div className="p-4 font-semibold">
                  <p>Media Sosial</p>
                </div>
                <div className="rounded-xl w-full p-4 border-t border-gray-200 bg-gray-50">
                  <div className='flex justify-around'>
                    {profile_data.Instagram && <Icons.MAGNET_INSTAGRAM
                      className='duration-500 cursor-pointer hover:-translate-y-1 '
                      onClick={() => window.open(profile_data.Instagram, '_blank')} />}
                    {profile_data.Linkedin && <Icons.MAGNET_LINKEDIN
                      className='duration-500 cursor-pointer hover:-translate-y-1 w-10'
                      onClick={() => window.open(profile_data.Linkedin, '_blank')} />}
                    {profile_data.Twitter && <Icons.MAGNET_TWITTER
                      className='duration-500 cursor-pointer hover:-translate-y-1 '
                      onClick={() => window.open(profile_data.Twitter, '_blank')} />}
                    {profile_data.Whatsapp && <Icons.MAGNET_WHATSAPP
                      className='duration-500 cursor-pointer hover:-translate-y-1 '
                      onClick={() => window.open(profile_data.Whatsapp, '_blank')} />}
                  </div>
                </div>
              </div>

              {is_own_profile && <button className='px-8 py-2 rounded-xl text-white bg-gradient-to-r from-magnet-rose-normal to-magnet-orange-normal hover:-translate-y-1 hover:opacity-80 duration-500 font-semibold'
                onClick={() => { TopFunction(); navigate("/profile/" + read_username() + "/sunting") }}>
                Sunting Profil
              </button>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserProfileView