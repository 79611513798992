import * as APP_DATA from "../../../core/ConstantData";
import MgtalksView from "./MgtalksView";
import React from "react";

class MgtalksController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    document.title = APP_DATA.APP_NAME;
    return <MgtalksView />;
  }
}

export default MgtalksController;